// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-jsx": () => import("./../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-hainamer-jsx": () => import("./../src/pages/hainamer.jsx" /* webpackChunkName: "component---src-pages-hainamer-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-product-jsx": () => import("./../src/pages/product.jsx" /* webpackChunkName: "component---src-pages-product-jsx" */),
  "component---src-templates-category-jsx": () => import("./../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-hainamer-jsx": () => import("./../src/templates/hainamer.jsx" /* webpackChunkName: "component---src-templates-hainamer-jsx" */),
  "component---src-templates-pricing-jsx": () => import("./../src/templates/pricing.jsx" /* webpackChunkName: "component---src-templates-pricing-jsx" */),
  "component---src-templates-product-jsx": () => import("./../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */),
  "component---src-templates-service-jsx": () => import("./../src/templates/service.jsx" /* webpackChunkName: "component---src-templates-service-jsx" */),
  "component---src-templates-tag-jsx": () => import("./../src/templates/tag.jsx" /* webpackChunkName: "component---src-templates-tag-jsx" */)
}

